<template>
  <div class="w-m-content financeiro-window">
    <!--  @submit.prevent="save" -->
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">{{ bem.nome }}</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div v-for="item in arr">
        <e-row>
            <p>
              <strong>{{ item.label }}:&nbsp; &nbsp;</strong>

            </p> 
            <p>  {{ item.value }}</p>
       
        </e-row>
      </div>
    </div>
  </div>
</template>

<script>
// @TODO: <V-2.2>: Tags
import ERow from '@/components/layout/components/Row'
import ECol from '@/components/layout/components/Col'

export default {
  name: 'infoResale',
  components: {
    ERow,
    // ECol,
  },
  data() {
    return {
    }
  },
  props: ['router', 'bem'],
  mounted() {
  },
  computed: {
    arr() {
      return Object.keys(this.bem).map(b => {
        return {
          label: b.replace('_', ' '),
          value: this.bem[b]
        }
      })
    }

  },
  methods: {
   
  }
}
</script>
